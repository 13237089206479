<template>
  <div>
    <base-navbar :backIcon="true" />
    <div :class="$style.profile" v-if="colleague">
      <img v-if="colleague.img_url" :src="colleague.img_url" />
      <svg
        v-else
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3ZM18 7.5C20.49 7.5 22.5 9.51 22.5 12C22.5 14.49 20.49 16.5 18 16.5C15.51 16.5 13.5 14.49 13.5 12C13.5 9.51 15.51 7.5 18 7.5ZM18 28.8C14.25 28.8 10.935 26.88 9 23.97C9.045 20.985 15 19.35 18 19.35C20.985 19.35 26.955 20.985 27 23.97C25.065 26.88 21.75 28.8 18 28.8Z"
        />
      </svg>
      <div :class="$style.values">
        <base-field label="Naam" :value="constructName(colleague)" />
        <base-field label="E-mail" :value="colleague.email" />
        <base-field label="Verjaardag" :value="colleague.birthdate" />
        <base-field label="Woonplaats" :value="colleague.city" />
        <base-field label="Tribe" :value="colleague.tribe" />
        <base-field label="Squad" :value="colleague.squad" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseField from '@/components/BaseField';
import BaseNavbar from '@/components/BaseNavbar';
export default {
  name: 'Profile',
  components: {
    BaseField,
    BaseNavbar,
  },
  computed: {
    colleague() {
      return this.$store.getters.getColleagueById(this.$route.params._id);
    },
  },
  methods: {
    constructName(colleague) {
      if (colleague.middlename) {
        return `${colleague.firstname} ${colleague.middlename} ${colleague.lastname} `;
      } else {
        return `${colleague.firstname} ${colleague.lastname} `;
      }
    },
  },
};
</script>

<style lang="scss" module>
.svg {
  width: clamp(1rem, 2rem, 3rem);
  height: clamp(1rem, 2rem, 3rem);
}
.profile {
  display: grid;
  place-content: center;

  img {
    place-self: center;
    width: clamp(80px, 10vw, 200px);
    height: clamp(80px, 10vw, 200px);
    border-radius: 50%;
    margin: $spacing-default;
  }

  svg {
    place-self: center;
    width: clamp(80px, 10vw, 200px);
    height: clamp(80px, 10vw, 200px);
    margin: $spacing-default;
  }
  path {
    fill: $primary;
  }

  .values {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    gap: $spacing-default;
    margin: $spacing-default;
  }
}

@media screen and (min-width: 480px) {
  .profile {
    .values {
      grid-template-columns: auto auto;
    }
  }
}
</style>
