<template>
  <div :class="$style.field">
    <label :class="$style.label">{{ label }}</label>
    <div :class="[$style.box, { empty: value === null }]">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseField',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: null,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" module>
.field {
  text-align: left;
  .label {
    &:after {
      content: '';
      display: inline-block;
      width: 0.25rem;
      height: 0.25rem;
      -moz-border-radius: 1rem;
      -webkit-border-radius: 1rem;
      border-radius: 50px;
      background-color: $secondary;
      margin-left: 2px;
    }
  }
  .box {
    margin-top: $spacing-small / 2;
    border: $primary;
    border-style: solid;
    border-width: 2px;
    padding: $spacing-small;
  }
}
</style>
<style>
.empty {
  height: 1rem;
}
</style>
